<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import CreditDialog from './components/agent_credit_dialog.vue'
import { getCashApi } from '@/api/cash'


/**
 * User list component
 */
export default {
  page: {
    title: "Agent Credit",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Agent Credit",
      items: [
        {
          text: "Account Payable",
          href: "/",
        },
        {
          text: "Agent Credit",
          href: "/",
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ID",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Agent",
          sortable: true,
          tdClass: 'align-middle'
        },

        {
          key: "Amount",
          sortable: false,
          tdClass: 'align-middle'
        },
        {
          key: "StartDate",
          tdClass: 'align-middle'

        },
        {
          key: "EndDate",
          sortable: true,
          tdClass: 'align-middle'
        },




        {
          key: "Desc",
          sortable: false,
          tdClass: 'align-middle'
        },

        {
          key: "Action",
          sortable: true,
          tdClass: 'align-middle'
        },
      ],

      credit: {
        amount      : 0,
        start_month : '',
        end_month   : '',
        credit_type : 'RECURRENT',
        fee_code    : 'OYIC'
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    CreditDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {

  },
  methods: {

    queryList() {
      getCashApi().agent_credts({ status: ['Pending', 'In use'], page: { page: this.currentPage, page_size: this.perPage } }).then((res) => {
        this.list = []
        res.data.map((o) => {

          this.list.push({
            id: o.id,
            avail_amount: o.avail_amount,
            used: o.expire_money,
            start_time: o.start_time,
            end_time: o.end_time,
            agent: o.agent ? o.agent : {},
            desc: o.desc,
            status: o.status,

          })
          this.totalRows = res.page.total;

        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    terminate_credit(credit) {
      getCashApi().agent_credit_discard({ id: credit.id }).then((res) => {
        if (res.errCode == 0) {

          this.$alertify.message("Agent Credit Discarded ");
          this.queryList()
        } else {
          this.$alertify.message("Failed to Discard " + res.msg);
        }

      })
    },

    credit_save(c) {

      getCashApi().agent_credit_setup(c).then((res) => {
        if (res.errCode == 0) {

          this.$alertify.message("Agent Credit Setuped ");
          this.queryList()
        } else {
          this.$alertify.message("Failed to Setup " + res.msg);
        }

      })
      this.$bvModal.hide('modal-credit-dialog')
    }

  },

};
</script>

<template>
  <Layout>
    <b-modal centerd id="modal-credit-dialog" title="New Agent Credit" size="lg" hide-footer>
      <CreditDialog :credit="credit" @cancel="$bvModal.hide('modal-credit-dialog')" @confirm="credit_save" />
    </b-modal>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">

              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2">

                  <b-button variant="primary" v-b-modal.modal-credit-dialog>Add Credit</b-button>


                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                  striped hover class="table-check">

                  <template #cell(ID)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.avail_amount.toLocaleString() }}
                  </template>

                  <template #cell(StartDate)="data">
                    {{ data.item.start_time }}
                  </template>


                  <template #cell(EndDate)="data">
                    {{ data.item.end_time }}
                  </template>

                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(Agent)="data">
                    {{ `${data.item.agent.first_name} ${data.item.agent.last_name}` }}
                  </template>
                  <template #cell(Desc)="data">
                    {{ data.item.desc }}
                  </template>
                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="terminate_credit(data.item)">Terminate</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
